import React from 'react';
import styled from 'styled-components';
const postParagraph = styled.p`
    margin-bottom: 12px;
    @media screen and (min-width: 1920px) {
        margin-bottom: 17px;
    }
`
const PostParagraph = (props) => {
    return (
        <postParagraph>{props.para}</postParagraph>
    );
};

export default PostParagraph;