// import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader";
import About from "../views/About/about"
import React, { Component } from 'react';
import Logo from "../images/logo.png"

class AboutPage extends Component {
  constructor(props) {
    super(props);
  }
  
  
  render() {    
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="About Us" url="https://thesikhmag.com/about"/>
        <About data={data}></About>
      </Layout>
    );
    
  }
}
export default AboutPage


export const query = graphql`
  query{
    allWpPage(filter: {slug: {eq: "about-us"}}) {
      nodes {
        content
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
`