import React, { Component } from 'react';
import {Link} from "gatsby";
import Nav from "../../components/NavigationBlog/NavigationBlog"
import ProgressBar from "../../elements/ProgressBar/ProgressBar"
import Divider from "../../elements/Divider/Divider"
import AboutImg from "../../images/The-Sikh-Banner.jpg"
import PostPara from "../../elements/PostParagraph/PostParagraph"
import PostPageButton from '../../elements/PostPageButton/PostPageButton'
import PostContentBlock from "../../elements/PostContentBlock/PostContentBlock"
import './css/about.css'
import {BodyWrapper,AboutUsWrap,AboutImageBlock,EmptyPageHeading,PostContentWrapper} from "../../css/style"
class about extends Component {
    constructor (props){
        super(props);
        this.state = {
            scrollPosition: 0,
        }
    }
    listenToScrollEvent = () => {
        document.addEventListener("scroll", () => {
          requestAnimationFrame(() => {
            this.calculateScrollDistance();
          });
        });
      }
    
      calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset; // how much the user has scrolled by
        const winHeight = window.innerHeight;
        const docHeight = this.getDocHeight();
    
        const totalDocScrollLength = docHeight - winHeight;
        const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)
        this.setState({
          scrollPostion,
        });
      }
    
      getDocHeight = () => {
        return Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        );
      }
      componentDidMount() {
        this.listenToScrollEvent();
      }
    render() {
        return (
            <BodyWrapper>
            <Nav></Nav>
            <ProgressBar scroll={this.state.scrollPostion + '%'} active={true} />
            <AboutUsWrap>
                {/* <Divider CategoryHeading={this.props.categoryName}></Divider> */}
                <AboutImageBlock>
                    <img src={this.props.data.allWpPage.nodes[0].featuredImage.node.mediaItemUrl} className="about-us-image"/>
                    <h1 className="about-us-overlay-heading">About Us</h1>                    
                </AboutImageBlock>
                <PostContentBlock padding="null">
                  <div dangerouslySetInnerHTML={{ __html: this.props.data.allWpPage.nodes[0].content }} style={{"width":"100%"}} /> 
                </PostContentBlock>
                
                {/* <p className="about-para">It has been a while that Sikhs have had a unique message to share - one of universality and equality. What continues to distinguish The Sikh is the belief that oneness should be an innate quality above and beyond caste, creed, gender or status. We may be a religion today with a clear identity as a “religion” but we place primary importance on the idea of blurring the lines/transcending differences between human beings and perceiving them as one. </p>
                <p className="about-para">The Sikh intends to bring forward how oneness is viewed through a lens of learning or Siksha. We must proclaim the nature of learning to be inclusive and introspective. We must make progress as culture and society move in many directions, constantly shaped by the forces of economics and sociology. We must progress yet keep intact our core identity and philosophy. We must assimilate not disintegrate. Virtuous through Siksha.</p> */}
                
                <Link to="/team" style={{"text-decoration":"none"}}>
                  <PostPageButton content="View Team" style={{"padding":"1% 1% 1% 1%"}}/>
                </Link>
            </AboutUsWrap>
            </BodyWrapper>
        );
    }
}
export default about;